@import "_variables.scss";

.ic-summary-landing-page,
.gl-summary-landing-page {
  .toast-conatiner {
    width: calc(var(--chakra-sizes-8xl) + var(--chakra-sizes-2));
    margin: 0 auto;
    padding: 0;
    border-radius: var(--chakra-radii-xl);
    border: 1px solid $color-stepper-status-inprogress;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--chakra-space-4);
    .toast-icon {
      width: 7%;
      border-top-left-radius: var(--chakra-radii-xl);
      border-bottom-left-radius: var(--chakra-radii-xl);
      background: $color-stepper-status-inprogress;
      img {
        margin: var(--chakra-space-10) auto;
      }
    }
    .toast-text {
      width: 75%;
      h5 {
        font-size: $font-size-med;
        font-weight: $font-weight-semi-bold;
        line-height: $line-height-medium;
      }
      p {
        font-size: $font-size-reg;
        font-weight: var(--chkara-fontWeights-normal);
        color: $color-font-card;
      }
    }
    .toast-divider {
      border: 1px solid $color-border;
      font-weight: var(--chkara-fontWeights-bold);
    }
    .toast-close {
      border-left: 1px solid $color-border;
      .close-icon {
        width: var(--chakra-sizes-6);
        height: var(--chakra-sizes-6);
        margin: var(--chakra-space-8);
      }
      .close-icon:hover {
        color: $color-link-hover-color;
      }
    }
  }

  .text-centered {
    margin: 0 auto;
    width: 604px;
    height: 327px;
    padding: 32px;
    background: $color-main-bg-color;
    .heading-text {
      width: 488px;
      font-family: $font-family-regular !important;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      text-align: center;
    }
    .paragraph-text {
      width: 540px;
      height: 129px;
      padding: 24px;
      border-radius: 16px;
      border: 1px solid $color-border;
      .paragraph-heading {
        font-family: $font-family-sfmono;
        font-weight: 500;

        text-align: center;
      }
      .paragraph {
        font-family: $font-family-regular;
        font-weight: 400;
        letter-spacing: 0.01em;
        text-align: center;
      }
    }
  }

  .resource-heading {
    text-align: left;
    font-family: $font-family-regular;
    font-size: $font-size-2rem;
    font-weight: 600;
    line-height: 28px;
    margin-top: var(--chakra-space-28);
    width: 65%;
  }

  .resource-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 24px;
    width: 65%;
    color: $color-default-font-color;
    font-family: $font-family-regular;
    .resource-title {
      font-weight: 600;
      letter-spacing: 0.01em;
    }
    .resource-caption {
      font-size: 12px;
      font-weight: 400;
      color: $color-stepper-indicator;
    }
    .resource-arrow {
      margin: 6px 12px 26px 0px;
    }
    .resource-body {
      margin: 6px 0px 6px 16px;
    }
  }

  .bottom-divider {
    border: 1px solid $color-border;
    margin-top: var(--chakra-space-32);
    width: 100%;
  }

  .resource-img {
    .resource-img-icon {
      width: var(--chakra-space-10);
      height: var(--chakra-space-10);
    }
  }
  .resource-img:hover {
    background-color: $color-bg-badge;
    border-radius: 50%;
    color: $color-link-hover-color;
  }
  .delete-btn {
    justify-content: center;
    &:hover {
      background: $color-sidebar-hover-bg !important;
    }
  }
}
.product-image {
  position: relative;
  background: transparent
    url("../../../../public/assets/images/dot-pattern-walkthrough.svg")
    no-repeat;
  background-size: cover;
  background-position: 0px 270px;
  width: 100%;
  height: 100%;
  padding-bottom: var(--chakra-space-12-8);
}
