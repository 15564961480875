@import "_variables.scss";

.content-container {
  margin: calc(var(--chakra-space-14) * -1) auto 0 auto;
  width: 65%;
  padding-bottom: var(--chakra-space-12-8);
  .content-title {
    .content-complete-status {
      // height/width of status circle
      flex: 0 0 118px;
      border: 1px solid $color-gradient-light-orange;
      border-radius: 50%;
      text-align: center;
      font-family: $font-family-regular;
      box-shadow: 0px 4px 14px 0px $box-shadow-status-sidebar;
      p {
        margin: 28px auto;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.01em;
        span {
          font-family: $font-family-sfmono;
          font-size: $font-size-32;
          font-weight: $font-weight-semi-bold;
          line-height: $line-height-xxlarge;
          padding-left: var(--chakra-space-2);
          padding-right: var(--chakra-space-2);
        }
      }
    }
    .content-title-text {
      margin-top: 15px;
      margin-left: 24px;
      width: 519px;
      height: 88px;
      h3 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
      }
      p {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $color-font-card;
      }
    }
    .resume-button {
      width: 142px;
      margin-left: 24px;
    }
  }

  .content-divider {
    width: auto;
    margin-left: calc(var(--chakra-space-12-8) * -1);
    margin-right: calc(var(--chakra-space-12-8) * -1);
    margin-top: var(--chakra-space-12-8);
    margin-bottom: var(--chakra-space-12-8);
    border: var(--chakra-space-px) solid $color-border;
  }

  .progress-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    box-shadow: none;
    border: 1px solid $color-border;
    margin-top: -5px;
    margin-left: 12px;
    .content {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: auto;
      .text-content {
        margin-left: 20px;
        h4 {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: $color-font-card;
          margin-top: 10px;
        }
        .record-row {
          border: 1px solid $color-border;
          margin-top: 10px;
          padding: 10px 20px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          position: relative;
          font-size: 16px;
          .check-img {
            color: transparent;
            position: absolute;
            left: -7px;
          }
          .edit-icon,
          .check-img {
            width: 15px;
            height: 15px;
          }
          .edit-wrapper {
            margin-left: auto;
          }
        }
      }
    }

    .edit-icon {
      margin-top: 5px;
      filter: brightness(0%);
    }
    .resume-button {
      margin-top: 30px;
    }
  }
  .inactive {
    margin-top: var(--chakra-space-6);
    opacity: 0.75;
  }

  .in-progress {
    margin-right: var(--chakra-space-5);
    border: 2px solid $color-link-hover-color;
    box-shadow: 0 var(--chakra-space-2) var(--chakra-space-6) 0
      $color-box-shadow;
  }

  .solid-border {
    border-left: 2px solid $color-font-product-name;
    height: var(--chakra-space-6);
    margin-left: var(--chakra-space-13);
    margin-bottom: var(--chakra-space-2);
  }

  .dashed-border-setup {
    .dashed-border {
      height: 80px;
      margin-left: 32px;
      border-left: 2px dashed $color-link-hover-color;
    }
    .down-icon {
      margin-top: -20px;
      margin-left: 17px;
      width: 32px;
      height: 32px;
      color: $color-link-hover-color;
    }
    .jump-back {
      font-family: $font-family-sfmono;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 16px;
    }
  }
}
